import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    meta: { title: "登录" },
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  }, {
    path: '/Home',
    name: 'Home',
    redirect: '/statistics',
    meta: { title: "首页" },
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    children: [
      {
        path: '/statistics',
        name: 'statistics',
        meta: { title: "首页" },
        component: () => import('../views/statistics.vue'),
      }, {
        path: '/memberList',
        name: 'memberList',
        meta: { title: "会员管理" },
        component: () => import('../views/memberMange/memberList.vue'),
      }, {
        path: '/memberDetails',
        name: 'memberDetails',
        meta: { title: "会员详情" },
        component: () => import('../views/memberMange/memberDetails.vue'),
      }, {
        path: '/saleProduct',
        name: 'saleProduct',
        meta: { title: "单桶管理", keepAlive: true, isBack: false },
        component: () => import('../views/singleBarrelManage/saleProduct.vue'),
      }, {
        path: '/offProduct',
        name: 'offProduct',
        meta: { title: "下架单桶" },
        component: () => import('../views/singleBarrelManage/offProduct.vue'),
      }, {
        path: '/customProduct',
        name: 'customProduct',
        meta: { title: "客户单桶" },
        component: () => import('../views/singleBarrelManage/customProduct.vue'),
      }, {
        path: '/Productdetails',
        name: 'Productdetails',
        meta: { title: "管理" },
        beforeEnter: (to, from, next) => {
          if (from.name == "saleProduct") {
            to.meta.isBack = true;
            to.meta.keepAlive = true;
          } else {
            to.meta.isBack = false;
            to.meta.keepAlive = true;
          }
          // else if (from.name != "projectWorkerDetails" && from.name != 'projectquestionDetails' && from.name != "projectTurnover" && from.name != "projectpunchRecord" && from.name != "projectlogDetails" && from.name != "meetinglistDetails" && from.name != "meetingrecordDetails" && from.name != "meetingNoticeDetails" && from.name != "projectWarehousetoolsrDetails") {
          //   to.meta.isBack = false;
          //   to.meta.keepAlive = true;
          // }
          next()
        },
        component: () => import('../views/singleBarrelManage/Productdetails.vue'),
      }, {
        path: '/offProductdetails',
        name: 'offProductdetails',
        meta: { title: "管理" },
        component: () => import('../views/singleBarrelManage/offProductdetails.vue'),
      }, {
        path: '/customProductdetails',
        name: 'customProductdetails',
        meta: { title: "管理" },
        component: () => import('../views/singleBarrelManage/customProductdetails.vue'),
      }, {
        path: '/brandmanage',
        name: 'brandmanage',
        meta: { title: "单桶品牌管理" },
        component: () => import('../views/singleBarrelManage/brandmanage.vue'),
      }, {
        path: '/bottingBrandmanage',
        name: 'bottingBrandmanage',
        meta: { title: "单瓶品牌管理" },
        component: () => import('../views/singleBarrelManage/bottingBrandmanage.vue'),
      }, {
        path: '/producingunitmanage',
        name: 'producingunitmanage',
        meta: { title: "产区管理" },
        component: () => import('../views/singleBarrelManage/producingunitmanage.vue'),
      }, {
        path: '/bucketmanage',
        name: 'bucketmanage',
        meta: { title: "桶型管理" },
        component: () => import('../views/singleBarrelManage/bucketmanage.vue'),
      }, {
        path: '/wineClassmanage',
        name: 'wineClassmanage',
        meta: { title: "酒品等级设置" },
        component: () => import('../views/singleBarrelManage/wineClassmanage.vue'),
      }, {
        path: '/wineryManage',
        name: 'wineryManage',
        meta: { title: "酒厂管理" },
        component: () => import('../views/singleBarrelManage/wineryManage.vue'),
      }, {
        path: '/storagemanage',
        name: 'storagemanage',
        meta: { title: "仓储保险管理" },
        component: () => import('../views/singleBarrelManage/storagemanage.vue'),
      }, {
        path: '/saleBottle',
        name: 'saleBottle',
        meta: { title: "单瓶管理" },
        component: () => import('../views/singleBottleManage/saleBottle.vue'),
      }, {
        path: '/bottleDetails',
        name: 'bottleDetails',
        meta: { title: "单瓶管理" },
        component: () => import('../views/singleBottleManage/bottleDetails.vue'),
      }, {
        path: '/offBottle',
        name: 'offBottle',
        meta: { title: "下架单瓶" },
        component: () => import('../views/singleBottleManage/offBottle.vue'),
      }, {
        path: '/orderList',
        name: 'orderList',
        meta: { title: "订单管理" },
        component: () => import('../views/orderMange/orderList.vue'),
      }, {
        path: '/orderDetails',
        name: 'orderDetails',
        meta: { title: "订单详情" },
        component: () => import('../views/orderMange/orderDetails.vue'),
      }, {
        path: '/bottleOrderList',
        name: 'bottleOrderList',
        meta: { title: "订单管理" },
        component: () => import('../views/orderMange/bottleOrderList.vue'),
      }, {
        path: '/bottleOrderDetails',
        name: 'bottleOrderDetails',
        meta: { title: "订单详情" },
        component: () => import('../views/orderMange/bottleOrderDetails.vue'),
      }, {
        path: '/afterSaleOrderList',
        name: 'afterSaleOrderList',
        meta: { title: "单瓶售后" },
        component: () => import('../views/orderMange/afterSaleOrderList.vue'),
      }, {
        path: '/afterSaleOrderDetails',
        name: 'afterSaleOrderDetails',
        meta: { title: "单瓶售后" },
        component: () => import('../views/orderMange/afterSaleOrderDetails.vue'),
      }, {
        path: '/saleApplicationList',
        name: 'saleApplicationList',
        meta: { title: "出售申请" },
        component: () => import('../views/orderMange/saleApplicationList.vue'),
      }, {
        path: '/saleApplicationDetails',
        name: 'saleApplicationDetails',
        meta: { title: "出售详情" },
        component: () => import('../views/orderMange/saleApplicationDetails.vue'),
      }, {
        path: '/bannerList',
        name: 'bannerList',
        meta: { title: "轮播图管理" },
        component: () => import('../views/materialMange/bannerList.vue'),
      }, {
        path: '/bannerDetails',
        name: 'bannerDetails',
        meta: { title: "轮播图详情" },
        component: () => import('../views/materialMange/bannerDetails.vue'),
      }, {
        path: '/articleList',
        name: 'articleList',
        meta: { title: "文章管理" },
        component: () => import('../views/materialMange/articleList.vue'),
      }, {
        path: '/articleDetails',
        name: 'articleDetails',
        meta: { title: "文章详情" },
        component: () => import('../views/materialMange/articleDetails.vue'),
      }, {
        path: '/informationList',
        name: 'informationList',
        meta: { title: "资讯管理" },
        component: () => import('../views/materialMange/informationList.vue'),
      }, {
        path: '/informationDetails',
        name: 'informationDetails',
        meta: { title: "资讯详情" },
        component: () => import('../views/materialMange/informationDetails.vue'),
      }, {
        path: '/infoclassify',
        name: 'infoclassify',
        meta: { title: "资讯分类管理" },
        component: () => import('../views/materialMange/infoclassify.vue'),
      }, {
        path: '/commonProblem',
        name: 'commonProblem',
        meta: { title: "常见问题" },
        component: () => import('../views/materialMange/commonProblem.vue'),
      }, {
        path: '/commonProblemDetails',
        name: 'commonProblemDetails',
        meta: { title: "问题详情" },
        component: () => import('../views/materialMange/commonProblemDetails.vue'),
      }, {
        path: '/problemclassify',
        name: 'problemclassify',
        meta: { title: "常见问题类型" },
        component: () => import('../views/materialMange/problemclassify.vue'),
      }, {
        path: '/contactUs',
        name: 'contactUs',
        meta: { title: "办公地点管理" },
        component: () => import('../views/materialMange/contactUs.vue'),
      }, {
        path: '/teamList',
        name: 'teamList',
        meta: { title: "团队管理" },
        component: () => import('../views/materialMange/teamList.vue'),
      }, {
        path: '/teamDetails',
        name: 'teamDetails',
        meta: { title: "人员详情" },
        component: () => import('../views/materialMange/teamDetails.vue'),
      }, {
        path: '/videoList',
        name: 'videoList',
        meta: { title: "视频管理" },
        component: () => import('../views/materialMange/videoList.vue'),
      }, {
        path: '/videoDetails',
        name: 'videoDetails',
        meta: { title: "视频详情" },
        component: () => import('../views/materialMange/videoDetails.vue'),
      }, {
        path: '/productDisplay',
        name: 'productDisplay',
        meta: { title: "单桶展示" },
        component: () => import('../views/materialMange/productDisplay.vue'),
      }, {
        path: '/bottleDisplay',
        name: 'bottleDisplay',
        meta: { title: "单瓶展示" },
        component: () => import('../views/materialMange/bottleDisplay.vue'),
      }, {
        path: '/visitingappointment',
        name: 'visitingappointment',
        meta: { title: "参观预约" },
        component: () => import('../views/serviceMange/visitingappointment.vue'),
      }, {
        path: '/visitingDetails',
        name: 'visitingDetails',
        meta: { title: "参观预约详情" },
        component: () => import('../views/serviceMange/visitingDetails.vue'),
      }, {
        path: '/assetApplication',
        name: 'assetApplication',
        meta: { title: "申请资产更新" },
        component: () => import('../views/serviceMange/assetApplication.vue'),
      }, {
        path: '/investmentGuide',
        name: 'investmentGuide',
        meta: { title: "申请投资指南" },
        component: () => import('../views/serviceMange/investmentGuide.vue'),
      }, {
        path: '/bottlingAppointment',
        name: 'bottlingAppointment',
        meta: { title: "装瓶预约" },
        component: () => import('../views/serviceMange/bottlingAppointment.vue'),
      }, {
        path: '/bottlingDetails',
        name: 'bottlingDetails',
        meta: { title: "装瓶预约详情" },
        component: () => import('../views/serviceMange/bottlingDetails.vue'),
      }, {
        path: '/customerService',
        name: 'customerService',
        meta: { title: "客服微信设置" },
        component: () => import('../views/systemsetting/customerService.vue'),
      }, {
        path: '/freightTemplate',
        name: 'freightTemplate',
        meta: { title: "运费模板" },
        component: () => import('../views/systemsetting/freightTemplate.vue'),
      }, {
        path: '/freightTemplateDetails',
        name: 'freightTemplateDetails',
        meta: { title: "运费模板" },
        component: () => import('../views/systemsetting/freightTemplateDetails.vue'),
      }, {
        path: '/couponMange',
        name: 'couponMange',
        meta: { title: "优惠券管理" },
        component: () => import('../views/systemsetting/couponMange.vue'),
      }
    ],
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 前置守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/') return next()
  const token = window.sessionStorage.getItem('token')
  // 如果没有token并且去跳其他页面的话跳登录页
  if (!token) return next('/')
  next()
})
router.afterEach(route => {
  // 从路由的元信息中获取 title 属性
  document.title = '博本管理后台';
  // if (route.meta.title) {
  //   document.title = route.meta.title;
  // }
});
export default router

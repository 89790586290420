import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import "./assets/font/iconfont"
import './assets/font/iconfont.css'
import * as ElIcon from '@element-plus/icons-vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

var app = createApp(App);
app.use(store).use(router).use(ElementPlus, {
    locale: zhCn,
})
Object.keys(ElIcon).forEach((key) => {
    app.component(key, ElIcon[key])
})
app.component('QuillEditor', QuillEditor)
app.mount('#app');